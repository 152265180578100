/**
 * returns a basic 200 (OK) Response
 * also returns the token auth headers
 */
const fakeHeader = (event, options = {}) => ({
  status: 200,
  statusText: 'OK',
  ...options,
  headers: {
    // do not return headers so new ones from the actual API wont be overwritten
    // 'uid': event.request.headers.get('uid'),
    // 'access-token': event.request.headers.get('access-token'),
    // 'client': event.request.headers.get('client'),
    'Content-Type': 'application/json',
    ...options.headers
  }
})

export default fakeHeader
