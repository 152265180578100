import { Fragment } from 'react'

import { Link } from 'react-router-dom'

import moment from 'moment'

export const de = {
  code: 'de',
  certification_states: {
    //---------------------------------------------------------
    // Status deprecated
    //---------------------------------------------------------
    billed: 'Abgerechnet',
    start_recertification: 'Rezertifizierungswunsch',
    canceled: 'Zertifizierungsvorgang abgebrochen',
    certificate_send: 'Zertifikat und Prüfbericht versandt',
    certificate_received: 'Zertifikat erhalten',
    collector_inquired: 'Erheber angefragt',
    collector_accepted: 'Erheber zugesagt und Prüfauftrag erteilt',
    collection_scheduled: 'Termin zur Erhebung vereinbart',
    collection_running: 'Erhebung läuft',
    collection_finished: 'Erhebung abgeschlossen',
    data_checked: 'Erhebungsdaten geprüft und Entwurf an Betrieb versandt',
    draft_declined: 'Entwurf von Betrieb abgelehnt',
    draft_confirmed: 'Entwurf von Betrieb bestätigt',
    owner_informed: 'Erhebungsvereinbarung versandt',
    ready_for_collection: 'Für Erhebung freigegeben',
    ready_for_report: 'Zur Auswertung freigegeben',
    signed_contract: 'Erhebungsvereinbarung unterschrieben',
    started: 'Zertifizierungswunsch',
    inquire_collector: 'Erheber anfragen',
    decline_collection: 'Erhebung ablehnen',
    accept_collection: 'Erhebung verbindlich zusagen',

    //---------------------------------------------------------
    // Events deprecated
    //---------------------------------------------------------
    send_contract: 'Erhebungsvereinbarung versenden',
    sign_contract: 'Erhebungsvereinbarung unterschreiben',
    check_contract: 'Unterschrift überprüft',
    schedule_collection: 'Termin zur Erhebung hinterlegen',
    start_collection: ' 	Erhebung starten',
    finish_collection: 'Erhebung abschließen',
    ask_question: 'Rückfrage stellen',
    mark_as_ready_for_report: 'Daten zur Auswertung freigeben',
    check_data_and_send_draft: 'Erhebungsdaten geprüft. Jetzt Entwurf an Betrieb senden.',
    decline_draft: 'Entwurf ablehnen',
    confirm_draft: 'Entwurf bestätigen',
    send_certificate: 'Zertifikat und Prüfbericht versenden',
    forward_certificate: 'Zertifkat erhalten und an Betrieb weitersenden',
    bill: 'Vorgang als abgerechnet markieren',
    cancel: 'Zertifizierungsvorgang abbrechen'
  },
  guest_groups: {
    allergic: 'Allergiker',
    deaf: 'Hörbehindert/ Gehörlos',
    generations: 'Bequem unterwegs für alle Generationen',
    mental: 'Kognitive Beeinträchtigungen',
    walking: 'Gehbehindert',
    wheelchair: 'Rollstuhlfahrer',
    visual: 'Sehbehindert/ Blind'
  },
  licenseOwners: {
    volumeUnitAccount: {
      openDetails: 'Volumeneinheiten-Verwaltung öffnen',
      history: 'Kontoverlauf',
      volumeUnits: 'Volumeneinheiten',
      dateTime: 'Datum und Uhrzeit',
      transactionAmount: 'Einheiten',
      creator: 'Ausführender Nutzer',
      comment: 'Kommentar',
      balance: 'Aktuelles Kontingent',
      transaction: {
        singular: 'Transaktion',
        plural: 'Transaktionen'
      },
      createTransaction: {
        heading: 'Transaktion hinzufügen',
        subtract: 'abziehen',
        add: 'hinzufügen',
        amount: 'Betrag',
        submit: ({ amount, positive }) =>
          `${Math.abs(amount)} Volumeneinheiten ${positive ? 'hinzufügen' : 'abziehen'}`,
        loadingMore: 'Lädt weitere Transaktionen...',
        errors: {
          general: {
            validationFailed: 'Speichern fehlgeschlagen. Bitte prüfen Sie die Eingabe.'
          },
          validationErrors: {
            amount: {
              required: 'Bitte Betrag angeben.',
              mustBeNonzero: 'Betrag muss ungleich 0 sein.'
            },
            comment: {
              required: 'Bitte Grund oder Kommentar angeben.'
            }
          }
        }
      },
      export: {
        monthRangePicker: {
          placeholder: 'gesamter Zeitraum'
        },
        downloadTooltip: '.xlsx Export-Datei Herunterladen'
      }
    }
  },
  live_pdf: {
    url_for_wheelchair_de: 'Rollstuhlfahrer/ Gehbehindert (Deutsch)',
    url_for_deaf_de: 'Hörbehindert/ Gehörlos (Deutsch)',
    url_for_visual_de: 'Sehbehindert/ Blind (Deutsch)',
    url_for_mental_de: 'Kognitive Beeinträchtigungen (Deutsch)',
    url_for_allergic_de: 'Allergie und Lebensmittelunverträglichkeit (Deutsch)',
    url_for_generations_de: 'Bequem unterwegs für alle Generationen (Deutsch)',
    url_for_walking_de: 'Senioren (nicht aktiv; Deutsch)',
    url_for_wheelchair_en: 'Rollstuhlfahrer/ Gehbehindert (Englisch)',
    url_for_deaf_en: 'Hörbehindert/ Gehörlos (Englisch)',
    url_for_visual_en: 'Sehbehindert/ Blind (Englisch)',
    url_for_mental_en: 'Kognitive Beeinträchtigungen (Englisch)',
    url_for_allergic_en: 'Allergie und Lebensmittelunverträglichkeit (Englisch)',
    url_for_generations_en: 'Bequem unterwegs für alle Generationen (Englisch)',
    url_for_walking_en: 'Senioren (nicht aktiv; Englisch)',
    short_reports: {
      de: 'Kurzbericht (deutsch)',
      en: 'Kurzbericht (englisch)'
    }
  },
  guest_group_description: {
    allergic: 'Informationen für Allergiker und Menschen mit Lebensmittelunverträglichkeiten',
    deaf: 'Informationen für Menschen mit Hörbehinderung und gehörlose Menschen',
    partially_deaf: 'Informationen für Menschen mit Hörbehinderung und gehörlose Menschen',
    generations: 'Bequem unterwegs für alle Generationen',
    mental: 'Informationen für Gäste mit kognitiven Beeinträchtigungen',
    visual: 'Informationen für Menschen mit Sehbehinderung und blinde Menschen',
    partially_visual: 'Informationen für Menschen mit Sehbehinderung und blinde Menschen',
    walking: 'Informationen für Gäste mit Gehbehinderung',
    wheelchair: 'Informationen für Menschen mit Gehbehinderung und Rollstuhlfahrer'
  },
  certification_level: {
    0: 'nicht barrierefrei',
    1: 'teilweise barrierefrei',
    2: 'barrierefrei'
  },
  guest_group_categories: {
    deaf: 'gehörlose Menschen',
    mental: 'Menschen mit kognitiver Beeinträchtigung',
    partially_deaf: 'Menschen mit Hörbehinderung',
    partially_visual: 'Menschen mit Sehbehinderung',
    visual: 'blinde Menschen',
    walking: 'Menschen mit Gehbehinderung',
    wheelchair: 'Menschen im Rollstuhl'
  },
  guest_group_category_description: {
    deaf: {
      1: 'teilweise barrierefrei für gehörlose Menschen',
      2: 'barrierefrei für gehörlose Menschen'
    },
    mental: {
      1: 'teilweise barrierefrei für Menschen mit kognitiver Beeinträchtigung',
      2: 'barrierefrei für Menschen mit kognitiver Beeinträchtigung'
    },
    partially_deaf: {
      1: 'teilweise barrierefrei für Menschen mit Hörbehinderung',
      2: 'barrierefrei für Menschen mit Hörbehinderung'
    },
    partially_visual: {
      1: 'teilweise barrierefrei für Menschen mit Sehbehinderung',
      2: 'barrierefrei für Menschen mit Sehbehinderung'
    },
    visual: {
      1: 'teilweise barrierefrei für blinde Menschen',
      2: 'barrierefrei für blinde Menschen'
    },
    walking: {
      1: 'teilweise barrierefrei für Menschen mit Gehbehinderung',
      2: 'barrierefrei für Menschen mit Gehbehinderung'
    },
    wheelchair: {
      1: 'teilweise barrierefrei für Menschen im Rollstuhl',
      2: 'barrierefrei für Menschen im Rollstuhl'
    }
  },
  date_picker: {
    months: {
      1: 'Januar',
      2: 'Februar',
      3: 'März',
      4: 'April',
      5: 'Mai',
      6: 'Juni',
      7: 'Juli',
      8: 'August',
      9: 'September',
      10: 'Oktober',
      11: 'November',
      12: 'Dezember'
    }
  },
  certificate_type: {
    missing: 'Keine Zertifizierung',
    documented: 'Information zur Barrierefreiheit',
    validated: 'Barrierefreiheit geprüft'
  },
  permitted_working_list: {
    offline: 'Offline verfügbar'
  },
  add: 'Hinzufügen',
  add_comment: 'kommentieren',
  add_comment_hint: (
    <>
      Hinweis: Dies löst <strong>keine</strong> Benachrichtigung bei den zuständigen Ansprechpartnern aus.
    </>
  ),
  add_contract: 'Vereinbarung anfügen',
  add_facility: 'neuen Betrieb anlegen',
  add_form: 'Formular hinzufügen',
  add_form_to_section: 'Formular zum Teilbereich hinzufügen',
  add_license_owner: 'Lizenznehmer hinzufügen',
  add_images: 'Bilder hinzufügen',
  add_region: 'Region hinzufügen',
  add_widget_filter: 'Widget Filter hinzufügen',
  add_role: 'Rechte-Gruppe hinzufügen',
  add_user: 'Nutzer hinzufügen',
  add_section: 'Teilbereich hinzufügen',
  additional_forms_count: 'Zusatzmodule',
  additional_images: 'zugehörige Fotos',
  message_issue_upload_image: 'Bitte laden Sie keine großen Daten hoch!',
  address: 'Anschrift',
  address_id: 'Adress ID',
  administration: 'Administration',
  audit_assignment_number: 'Prüfauftragsnummer',
  audit_report: 'Prüfbericht',
  artist: 'Urheber',
  base_information: 'Allgemeine Informationen',
  booking_code: 'Buchungscode',
  calculating: 'Wird ermittelt',
  cancel: 'abbrechen',
  cancel_certification: 'Zertifizierung abbrechen',
  certificate_level: 'Zertifikat (erreichte Stufen)',
  certification_status: 'Zertifizierungsstatus',
  certified_from: 'Zertifiziert von',
  certified_to: 'Zertifiziert bis',
  change_status: 'Status ändern in',
  changed_from: 'geändert durch',
  city: 'Ort',
  clone: 'kopieren',
  close: 'schließen',
  collection_desired_until: 'Erhebungsfrist',
  collection_scheduled: 'Bearbeitungsfrist',
  collection_scheduled_until: 'Spätester Erhebungstermin',
  collector: 'Erheber',
  collector_id: 'Erheber-ID',
  collector_reward: 'Erheberhonorar',
  collectors: 'Erheber',
  comment: 'Kommentar',
  comment_certification: 'Kommentar abgeben',
  comments: 'Kommentare',
  copyright: 'Rechteinhaber',
  confirm_delete_collector_description: 'Sind Sie sich sicher, dass Sie den Erheber löschen möchten?',
  confirm_delete_description: 'Sind Sie sich sicher, dass Sie den Teilbereich löschen möchten?',
  confirm_delete_header: 'wirklich löschen?',
  confirm_delete_image_description: 'Sind Sie sich sicher, dass Sie das Bild löschen möchten?',
  confirm_delete_license_owner_description: 'Sind Sie sich sicher, dass Sie diesen Lizenznehmer löschen möchten?',
  confirm_delete_region_description: 'Sind Sie sich sicher, dass Sie die Region löschen möchten?',
  confirm_delete_widget_filter_description: 'Sind Sie sich sicher, dass Sie den Widget Filter löschen möchten?',
  confirm_delete_role_description: 'Sind Sie sich sicher, dass Sie die Rechte-Gruppe löschen möchten?',
  confirm_delete_user_description: 'Sind Sie sich sicher, dass Sie diesen Nutzer löschen möchten?',
  confirm_delete_user_header: 'wirklich löschen?',
  confirm_delete_version_description: 'Sind Sie sich sicher, dass Sie die Version löschen möchten?',
  contact_person: 'Ansprechpartner',
  contact_person_id: 'Ansprechpartner ID',
  country: 'Land',
  country_code: 'Länderkennung',
  created_at: 'angelegt am',
  create_default_section: 'neuen Standard-Teilbereich erstellen',
  show_section_in_short_report: 'Standardmäßig im Kurzbericht ausgeben',
  show_section_in_short_report_hint:
    'Teilbereiche, die aus diesem Standard-Teilbereich erstellt werden, bekommen diese Einstellung übertragen. Danach lässt sie sich für jeden Teilbereich individuell anpassen.',
  create_manual: 'Datei hochladen',
  data_collected_at: 'Erhebungstermin',
  date: 'Datum',
  default: 'Standard',
  default_dsft_user: 'Zuständiger DSFT Mitarbeiter',
  default_user: 'Standard Mitarbeiter',
  organizations: 'Organisationen',
  delete: 'löschen',
  delete_default_section: 'Standard-Teilbereich löschen',
  delete_facility: 'Betrieb löschen',
  delete_offline_facility: 'Offline Betrieb löschen',
  delete_license_owner: 'Lizenznehmer löschen',
  delete_region: 'Region löschen',
  delete_widget_filter: 'Widget Filter löschen',
  delete_role: 'Rechte-Gruppe löschen',
  delete_section: 'Teilbereich löschen',
  delete_user: 'Nutzer löschen',
  delete_version: 'Version löschen',
  delete_manual: 'Datei löschen',
  description_de: 'Beschreibung (deutsch)',
  description_en: 'Beschreibung (englisch)',
  dublicate: 'kopieren',
  download: 'herunterladen',
  choose: 'auswählen',
  sync: 'synchronisieren',
  not_allowed_to_sync: 'Sie sind nicht (mehr) berechtigt, Ihre offline Daten mit der Datenbank zu synchronisieren',
  download_barrierfree_pdfs: 'Download barrierefreie PDFs',
  dsft_permission: 'DSFT-Nutzer Berechtigung',
  edit: 'bearbeiten',
  edit_certification: 'Zertifizierung bearbeiten',
  edit_default_section: 'Standard-Teilbereich bearbeiten',
  edit_images: 'Bilder bearbeiten',
  edit_training_information: 'Schulungsinformationen bearbeiten',
  editing: 'Bearbeitung',
  email: 'E-Mail-Adresse',
  email_notifications: 'E-Mail Benachrichtigungen',
  facilities: 'Betriebe',
  facility_kinds: 'Art des Betriebs',
  facility_name: 'Name des Betriebs',
  offline_facility_hint: ({ downloaded }) =>
    `Sie haben diesen Betrieb am ${moment(downloaded).format('L')} (${moment(
      downloaded
    ).fromNow()}) heruntergeladen und bearbeiten ihn gerade lokal. Alle Änderungen werden erst nach dem Synchronisieren in die Datenbank übertragen.`,
  failed: 'nicht bestanden',
  fax: 'Fax',
  fields: 'Felder',
  file: 'Datei',
  first_name: 'Vorname',
  form_count: 'Anzahl der Formulare',
  form_construction_kit: 'Formularbaukasten',
  form_data_count: 'ausgefüllte Formulare',
  from: 'vom',
  function: 'Funktion',
  guest_group: 'Gästegruppe',
  history: 'Historie',
  image_description: 'Bildbeschreibung',
  image_description_visual: 'Bildbeschreibung für sinnesbeeinträchtige Personen',
  image_title: 'Titel des Bildes',
  inspection_order: 'Prüfauftrag',
  institution: 'Institution/Anbieter',
  is_sublicense_owner: 'ist Unterlizenznehmer von',
  label: 'Bezeichnung',
  last_login: 'letzter Login',
  last_modified: 'letzte Änderung',
  last_name: 'Nachname',
  lat: 'Latitude',
  license_owner_new: 'neuen Lizenznehmer anlegen',
  license_owners: 'Lizenznehmer',
  linked_facility_kinds_count: 'Verknüfte Angebotstypen',
  login: 'anmelden',
  logout: 'abmelden',
  lon: 'Longitude',
  makeCurrentVersion: 'als Standardversion setzen',
  move: 'verschieben',
  missing: 'Fehlende Angaben. Bitte nachprüfen!',
  name: 'Name',
  name_de: 'Name (deutsch)',
  name_en: 'Name (englisch)',
  no_comments: 'Keine Kommentare',
  offer_price: 'Angebotspreis',
  offer_type: 'Angebotstyp',
  offer_types: 'Angebotstypen',
  passed: 'Erfolgreich bestanden',
  password: 'Passwort',
  password_confirmation: 'Passwort wiederholen',
  phone: 'Telefon',
  private_image: 'nicht öffentliches Foto',
  public_image: 'öffentliches Foto',
  recertification: 'Erst-/Re-Zertifizierung',
  regions: 'Regionen',
  widget_filters: 'Widget Filter',
  related_users: 'zugehörige Nutzer',
  request_date: 'Anfragedatum',
  reporting_result: 'Gesamtergebnis',
  roles: 'Rechte-Gruppen',
  role_to_set_rights: 'Rechte-Gruppe',
  salutation: 'Anrede',
  save: 'speichern',
  create: 'hinzufügen',
  section: 'Teilbereich',
  section_new: 'Neuen Teilbereich anlegen',
  sections: 'Teilbereiche',
  select: {
    placeholder: 'Auswählen …',
    loadingMessage: _ => 'Lädt …',
    noOptionsMessage: _ => 'Keine Ergebnisse'
  },
  short_name: 'Kurzname',
  seminar_number: 'Seminarnummer',
  show_all: 'Alle anzeigen',
  show_all_comments: 'Alle Kommentare anzeigen',
  show_more: 'mehr anzeigen',
  show_reportings: 'Prüfbericht anzeigen',
  sublicense_owner: 'Sub-Lizenznehmer',
  standard_forms_count: 'Standardmodule',
  state: 'Bundesland',
  status: 'Status',
  street: 'Straße',
  street_addition: 'Straße (Zusatz)',
  time: 'Uhrzeit',
  title: 'Titel',
  to: 'bis',
  tooltip_comment: 'Kommentar angefügt',
  tooltip_exchange: 'Alternative zu:',
  training_completed: 'Schulung abgeschlossen',
  training_information: 'Schulungsinformationen',
  training_pending: 'Schulung ausstehend',
  type: 'Typ',
  unset: 'nicht vorhanden',
  uploading: 'wird hochgeladen',
  user_management: 'Nutzerverwaltung',
  users: 'Benutzer',
  uuid: 'Uuid (nur Admin)',
  version: 'Version',
  versions: 'Versionen',
  website: 'Webseite',
  zipcode: 'Postleitzahl',
  // facility_kind: 'Angebotstyp',
  collection_requested_at: 'Anfragedatum',
  duplication_jobs_running:
    'Aktuell werden im Hintergrund Formulardaten kopiert. Deshalb werden möglicherweise noch nicht alle Formulare angezeigt. Laden Sie die Seite neu, um den aktuellen Status zu sehen. Dies kann einige Zeit in Anspruch nehmen.',
  yes: 'Ja',
  no: 'Nein',

  //---------------------------------------------------------
  // charges list
  //---------------------------------------------------------
  collection: 'Erhebungskosten',
  collection_price: 'Erhebungspreis',
  certification: 'Zertifizierung',
  organisation: 'Organisation / Abwicklung',
  other: 'Sonstiges',
  discount: 'Abzgl. Rabatt / Förderung',
  total: 'Gesamtangebotspreis netto',
  //
  // Touristische Daten
  //
  touristic_short_description_de: 'Touristische Kurz-Beschreibung (de)',
  facility_name_de: 'Betriebsname (de)',
  region_de: 'Region (de)',
  widget_filter_de: 'Widget Filter (de)',
  touristic_description_de: 'Detailangaben (de)',
  touristic_note_de: 'Bemerkungen (de)',
  service_info_de: 'Servicehinweise (de)',
  booking_info_de: 'Buchungshinweise (de)',
  touristic_short_description_en: 'Touristische Kurz-Beschreibung (en)',
  touristic_description_en: 'Detailangaben (en)',
  touristic_note_en: 'Bemerkungen (en)',
  service_info_en: 'Servicehinweise (en)',
  booking_info_en: 'Buchungshinweise (en)',
  facility_name_en: 'Betriebsname (en)',
  region_en: 'Region (en)',
  widget_filter_en: 'Widget Filter (en)',

  //new structure

  contact_user: {
    first_name: 'Vorname',
    last_name: 'Nachname',
    email: 'Email',
    phone: 'Telefon',
    salutation: 'Anrede',
    creation_description:
      'Hier kann ein neues Nutzerkonto mit den eingegebenen Daten angelegt und direkt verknüpft werden. Ein zufällig generiertes Passwort wird dann an die angegebene E-Mail-Adresse verschickt, damit sich dieser Nutzer im System einloggen kann. Sollten Sie die Fehlermeldung "Diese E-Mail-Adresse ist bereits vergeben!" erhalten, dann existiert die Person bereits in der Datenbank und Sie können diese über "Nutzer auswählen" verknüpfen, falls Sie die Berechtigung dazu besitzen.'
  },

  facility_contacts: {
    actions: {
      edit_static: {
        label: 'Daten bearbeiten',
        tooltip: 'Daten bearbeiten, ohne ein eigenes Nutzerkonto anzulegen.'
      },
      create: {
        label: 'Neuen Nutzer anlegen',
        tooltip: 'Neues Nutzerkonto anlegen und verknüpfen.'
      },
      select: {
        label: 'Nutzer auswählen',
        tooltip: 'Bestehendes Nutzerkonto verknüpfen.'
      },
      unlink: {
        label: 'Nutzer-Verknüpfung aufheben',
        tooltip:
          'Bestehende Verknüpfung zu einem Nutzerkonto entfernen. Danach können Sie wieder einen neuen Nutzer anlegen oder Daten ohne Bezug zu einem Nutzerkonto einpflegen.'
      }
    }
  },

  contact_person_certification: {
    headline: 'Ansprechpartner Zertifizierung',
    contact_user: 'Anspr. Betrieb',
    license_owner_user: 'Anspr. Lizenznehmer',
    collector: 'Erheber',
    secondary_collector: 'Weiterer Erheber',
    dsft_user: 'Anspr. Prüfstelle'
  },

  facilitiesExport: {
    generate: 'Daten exportieren',
    generateHint: 'Erzeugt im Hintergrund eine .xlsx Datei mit allen Betrieben des aktuell eingestellten Filters.',
    processing: 'Export wird erzeugt',
    saving: 'speichert…',
    waiting: 'Export wartet auf Start',
    checking: 'Status wird geprüft',
    download: count => (
      <Fragment>
        .xlsx Export mit{' '}
        <strong>
          {count} Betrieb{count == 1 ? '' : 'en'}
        </strong>{' '}
        herunterladen.
      </Fragment>
    ),
    fileCreated: time => (
      <Fragment>
        Erzeugt am <strong>{time.format('D. MMMM, H:mm')}</strong>.
      </Fragment>
    ),
    expire: time => (
      <Fragment>
        Wird <strong>{time.fromNow()}</strong> gelöscht.
      </Fragment>
    )
  },

  base_data: {
    plural: 'Allgemeine Daten',
    name_de: 'Name (de)',
    name_en: 'Name (en)',
    description_de: 'Untertitel/Namenszusatz (de)',
    description_en: 'Untertitel/Namenszusatz (en)',
    fax: 'Fax',
    email: 'Email',
    phone: 'Telefon',
    website: 'Webseite',
    region_ids: 'Regionen',
    widget_filter_ids: 'Widget Filter',
    touristic_short_description_de: 'Touristische Kurz-Beschreibung (de)',
    touristic_description_de: 'Detailangaben (de)',
    touristic_note_de: 'Bemerkungen (de)',
    service_info_de: 'Servicehinweise (de)',
    booking_info_de: 'Buchungshinweise (de)',
    touristic_short_description_en: 'Touristische Kurz-Beschreibung (en)',
    touristic_description_en: 'Detailangaben (en)',
    touristic_note_en: 'Bemerkungen (en)',
    service_info_en: 'Servicehinweise (en)',
    booking_info_en: 'Buchungshinweise (en)',
    overview_de: 'Überblick (de)',
    overview_en: 'Überblick (en)',
    accessibility_at_a_glance_de: 'Barrierefreiheit auf einem Blick (de)',
    accessibility_at_a_glance_en: 'Barrierefreiheit auf einem Blick (en)',
    address: {
      singular: 'Adresse',
      city: 'Ort',
      country_code: 'Land',
      state: 'Bundesland',
      street: 'Straße',
      street_addition: 'Straße (Zusatz)',
      lat: 'Breitengrad',
      lon: 'Längengrad',
      zipcode: 'Postleitzahl',
      region_ids: 'Regionen',
      geocode: 'Koordinaten berechnen'
    },
    contact_person: {
      singular: 'Ansprechpartner',
      salutation: 'Anrede',
      title: 'Titel',
      first_name: 'Vorname',
      last_name: 'Nachname',
      email: 'Email',
      phone: 'Telefon',
      function: 'Funktion'
    },
    accessibility_overview: {
      tab_name: 'Überblick/Barrierefreiheit auf einem Blick'
    },
    organization_ids: 'Organisationen',
    organizations_help: 'Die Zuweisung zu Organisationen hat z.B. Auswirkung auf die Sichtbarkeit im PartnerGateway.',
    metaDescription: 'Meta-Beschreibung'
  },

  accounting_information: {
    singular: 'Abrechnung',
    calculatedFacilitySize: 'Errechnete Betriebsgröße',
    calculatedVolumeUnitCosts: 'Beim Lizenznehmer abzuziehende Volumeneinheiten',
    volumeUnitCostCannotBeCalculatedHint:
      'Auf Grund des zugewiesenen Angebotstyps oder der errechneten Betriebsgröße können keine Volumeneinheiten errechnet werden.',
    firstCertification: 'Erstzertifizierung',
    recertification: 'Rezertifizierung',
    licence_owner_billed: 'Mit Lizenznehmer abgerechnet',
    bill_date: 'Rechnungsdatum',
    bill_number: 'Rechnungsnummer',
    licence_owner_billing_type: 'Art der Abrechnung mit Lizenznehmer',
    billing_type: 'Art der Abrechung',
    licence_owner_billing_details: 'Details zur Art der Abrechnung (z.B. zum Mengenpaket)',
    facility_billed: 'Direkt mit Betrieb abgerechnet',
    collector_billed: 'Mit Erheber abgerechnet',
    sticker_sent: 'Aufkleber versandt',
    sign_sent: 'Schild versandt',
    comment: 'Sonstiges / Kommentare',
    other: 'Sonstige Angaben'
  },

  search_criteria: {
    plural: 'Suchkriterien',
    edit: 'bearbeiten',
    no_chosen: '-'
  },

  charges_list: {
    singular: 'Preisliste',
    plural: 'Preislisten',
    certification: 'Zertifizierung',
    collection: 'Erhebungskosten',
    discount: 'Abzügl. Rabatt / Förderung',
    organisation: 'Organisation / Abwicklung',
    other: 'Sonstiges'
  },

  training_participant: {
    singular: 'Schulungsabsolvent',
    plural: 'Schulungsabsolventen',
    first_name: 'Vorname',
    last_name: 'Nachname',
    salutation: 'Anrede'
  },

  training_participation: {
    kind: 'Typ',
    passed_at: 'Datum',
    seminar_number: 'Seminarnummer',
    comment: 'Kommentar'
  },

  user: {
    singular: 'Benutzer',
    plural: 'Benutzer',
    salutation: 'Anrede',
    system: 'System',
    institution: 'Institution/Anbieter',
    notes: 'Notiz (intern)',
    collector_id: 'ID des Erhebers',
    info: {
      more_info: 'Weitere Informationen'
    },
    phone: 'Telefon',
    license_owner_id: 'Arbeitet für Lizenznehmer',
    first_name: 'Vorname',
    last_name: 'Nachname',
    email: 'Email',
    email_notifications: 'Email Benachrichtigungen',
    password: 'neues Passwort',
    password_confirmation: 'Passwort wiederholen',
    current_password: 'aktuelles Passwort',
    address: {
      singular: 'Adresse',
      plural: 'Adressen',
      street: 'Straße',
      street_addition: 'Straße (Zusatz)',
      zipcode: 'Postleitzahl',
      city: 'Ort',
      state: 'Bundesland',
      country_code: 'Land'
    }
  },
  facilitiesDashboard: {
    heading: 'Ihre Betriebe',
    facilitiesTable: {
      licenseOwner: 'Lizenznehmer',
      certificateState: 'Zertifizierungsstatus',
      auditOrderNumber: 'Prüfauftragsnummer',
      name: 'Name des Betriebs',
      city: 'Ort',
      regions: 'Regionen',
      bundles: 'Betriebsbündel',
      forms: 'Formulare',
      contactName: 'Ansprechpartner',
      contactEmail: 'E-Mail',
      contactPhone: 'Telefon',
      collectorName: 'Erheber',
      collectionDesiredUntil: 'Spätester Erhebungstermin',
      collectionDate: 'Erhebungstermin',
      trainingState: 'Schulungsstatus',
      certifiedTo: 'Ablauf der Zertifizierung',
      certificate: 'Zertifikat'
    },
    searchQuery: 'Suchen...',
    sorting: {
      label: 'Sortieren nach',
      types: {
        UPDATED_AT: 'Letzte Änderung',
        NAME: 'Name des Betriebes',
        LICENSE_OWNER: 'Name des Lizenznehmers',
        WORKFLOW_STATE_CHANGED_AT: 'Letzter Statuswechsel',
        WORKFLOW_STATE: 'Zertifizierungsstatus',
        COLLECTION_DESIRED_UNTIL: 'Erhebungsfrist',
        COLLECTION_DATE: 'Erhebungstermin',
        CERTIFIED_TO: 'Ablauf der Zertifizierung'
      },
      directions: {
        UPDATED_AT: { ASCENDING: 'Älteste zuerst', DESCENDING: 'Neueste zuerst' },
        NAME: { ASCENDING: 'Alphabetisch aufsteigend (A-Z)', DESCENDING: 'Alphabetisch absteigend (Z-A)' },
        LICENSE_OWNER: { ASCENDING: 'Alphabetisch aufsteigend (A-Z)', DESCENDING: 'Alphabetisch absteigend (Z-A)' },
        WORKFLOW_STATE_CHANGED_AT: { ASCENDING: 'Älteste zuerst', DESCENDING: 'Neueste zuerst' },
        WORKFLOW_STATE: { ASCENDING: 'Anfang des Workflows zuerst', DESCENDING: 'Ende des Workflows zuerst' },
        COLLECTION_DESIRED_UNTIL: { ASCENDING: 'Früheste zuerst', DESCENDING: 'Späteste zuerst' },
        COLLECTION_DATE: { ASCENDING: 'Früheste zuerst', DESCENDING: 'Späteste zuerst' },
        CERTIFIED_TO: { ASCENDING: 'Früheste zuerst', DESCENDING: 'Späteste zuerst' }
      }
    },
    filters: {
      filter: 'Filtern',
      reset: 'zurücksetzen',
      workflowStates: {
        label: 'Zertifizierungsstatus',
        emptyPlaceholder: 'Egal'
      },
      trainingStatus: {
        label: 'Schulungsstatus',
        emptyPlaceholder: 'Egal',
        options: {
          COMPLETED: 'abgeschlossen',
          PENDING: 'ausstehend'
        }
      },
      regions: {
        label: 'Regionen',
        emptyPlaceholder: 'Alle',
        none: '-- keine --'
      },
      licenseOwner: {
        label: 'Lizenznehmer',
        emptyPlaceholder: 'Alle',
        none: '-- keiner --'
      },
      collector: {
        label: 'Erheber',
        emptyPlaceholder: 'Alle',
        none: '-- keiner --'
      },
      certificationTypes: {
        label: 'Zertifizierungsart',
        emptyPlaceholder: 'Egal',
        options: {
          MISSING: 'keine Zertifizierung',
          DOCUMENTED: 'Informationen zur Barrierefreiheit',
          VALIDATED: 'Barrierefreiheit geprüft'
        }
      },
      certificationEndDateRange: {
        label: 'Enddatum der Zertifizierung',
        placeholder: 'Egal'
      }
    }
  },
  facility: {
    singular: 'Betrieb',
    plural: 'Betriebe',
    noneFound: 'Keine Betriebe gefunden',
    name_de: 'Name (de)',
    description_de: 'Beschreibung (de)',
    license_owner_id: 'Lizenznehmer',
    kind_ids: 'Art des Betriebs',
    contact_user: {
      email: 'Email',
      email_notifications: 'Email Benachrichtigung',
      first_name: 'Vorname',
      last_name: 'Nachname',
      salutation: 'Anrede',
      title: 'Titel',
      function: 'Funktion',
      phone: 'Telefon',
      address: {
        city: 'Stadt',
        street: 'Straße',
        street_addition: 'Straße (Zusatz)',
        zipcode: 'Postleitzahl',
        state: 'Bundesland',
        country_code: 'Land'
      },
      password: 'Passwort',
      password_confirmation: 'Passwort wiederholen'
    },
    provider: {
      none: 'Kein Anbieter ausgewählt',
      update: 'Anbieter auswählen',
      edit: 'Anbieter bearbeiten',
      create: 'neuen Anbieter erstellen',
      none_found: 'Keine Anbieter gefunden'
    },
    bundleInformation: {
      label: 'Betriebs-Bündel',
      offline: 'offline nicht verfügbar',
      bundledBy: 'In Bündeln enthalten',
      bundledFacilities: 'Gebündelte Betriebe',
      noBundledFacilities: 'Es werden keine Betriebe gebündelt.',
      noBundledBy: 'Der Betrieb gehört zu keinem Bündel.',
      addBundledFacilities: 'Zu bündelnde Betriebe hinzufügen',
      removeBundledFacility: 'Betrieb aus dem Bündel löschen.',
      addBundledFacilitiesSuccess: count =>
        `Es wurde${count != 1 ? 'n' : ''} ${count} Betrieb${count != 1 ? 'e' : ''} hinzugefügt.`,
      tooltips: {
        bundledBy: {
          one: ({ name, audit_order_number }) =>
            `Dieser Betrieb gehört zum Bündel '${name}'${audit_order_number ? `(${audit_order_number})` : ''}`,
          many: ({ name, audit_order_number, count }) =>
            `Dieser Betrieb gehört zum Bündel '${name}'${audit_order_number ? `(${audit_order_number})` : ''} und ${
              count - 1
            } weiteren.`
        },
        bundledFacilities: {
          one: ({ name, audit_order_number }) =>
            `Dieser Betrieb bündelt den Betrieb '${name}'${audit_order_number ? `(${audit_order_number})` : ''}`,
          many: ({ name, audit_order_number, count }) =>
            `Dieser Betrieb bündelt '${name}'${audit_order_number ? `(${audit_order_number})` : ''} und ${
              count - 1
            } weitere${count > 2 ? '' : 'n'}.`
        }
      }
    },
    version_info: {
      current: 'aktuell',
      currentHelp: 'Es wird die aktuellste Version des Betriebes angezeigt',
      archived: 'archiviert',
      archivedHelp: facilityUuid => (
        <>
          Es wird eine archivierte Version des Betriebes angezeigt. <br />
          <Link to={`/facility/${facilityUuid}`}>Zur aktuellen Version</Link>
        </>
      ),
      shownVersionHelp: versionNumber => `Es wird Version ${versionNumber} des Betriebes angezeigt`,
      publicVersionHelp: (facilityUuid, publicVersionNumber) => (
        <>
          Der Betrieb ist in{' '}
          <Link to={`/facility/${facilityUuid}/${publicVersionNumber}`}>Version {publicVersionNumber}</Link> über das
          PartnerGateway veröffentlicht
        </>
      ),
      publicNotCurrentNotice:
        'Der Betrieb wird nicht in der aktuellsten Version veröffentlicht. Änderungen an Formularen, Bildern und einigen Basisdaten werden nicht über das PartnerGateway veröffentlicht.'
    },
    userAssignments: {
      heading: 'Weitere Ansprechpartner', // TODO: might be changed, as soon as these assignments replace the old assignments completely
      loadMore: ({ remaining }) => `weitere anzeigen (${remaining} verbleibend)`,
      roles: {
        // NOTE: keys are the values from AssignedUserRoleEnum (GraphQl)
        FACILITY_CONTACT: 'Anspr. Betrieb'
      },
      createAssignment: {
        heading: 'Neue Zuweisung',
        role: 'Zuweisen als',
        chooseRole: 'Rolle auswählen',
        forms: {
          FACILITY_CONTACT: {
            assignExistingUser: {
              heading: 'Nutzer auswählen',
              submit: ({ name }) => `${name} als betrieblichen Ansprechpartner zuweisen`,
              submitBlocked: 'Bitte einen Nutzer auswählen',
              validationErrors: {
                role: {
                  cannotAssignMoreOfThisType:
                    'Es können maximal zwei zusätzliche betriebliche Ansprechpartner zugewiesen werden.'
                },
                userIdToAssign: {
                  alreadyAssignedAsThisRole: 'Dieser Nutzer ist bereits als betrieblicher Ansprechpartner zugewiesen.'
                }
              }
            },
            createAndAssignNewUser: {
              heading: 'Neuen Nutzer anlegen',
              moreInfo: 'Mehr Informationen',
              submit: 'Neuen Nutzer anlegen und als betrieblichen Ansprechpartner zuweisen',
              validationErrors: {
                role: {
                  cannotAssignMoreOfThisType:
                    'Es können maximal zwei zusätzliche betriebliche Ansprechpartner zugewiesen werden.'
                }
              }
            }
          }
        }
      },
      removeAssignment: {
        heading: 'Zuweisung aufheben',
        confirm: 'Aufheben',
        message: ({ name, role }) => (
          <>
            Zuweisung von <span className="text-primary-light">{name}</span> als{' '}
            <span className="text-primary-light">{role}</span> aufheben?
          </>
        )
      }
    },
    invoiceRecipient: {
      label: 'Rechnungsanschrift',
      recipientName: 'Firmierung',
      email: 'E-Mail',
      phoneNumber: 'Telefon',
      address: {
        street: 'Straße',
        street_addition: 'Straße (Zusatz)',
        zipcode: 'Postleitzahl',
        city: 'Ort',
        state: 'Bundesland',
        country: 'Land'
      }
    }
  },

  settings: {
    plural: 'Einstellungen',
    singular: 'Einstellung',
    email: 'Email',
    password: 'Password',
    loading: 'Ihre Einstellungen werden geladen ...',

    //partner_gateway_settings.listed
    listed: 'veröffentlichen',
    en_available: 'englisch verfügbar',
    publication_allowed: 'Einverständnis zur Veröffentlichung',
    publication_comments: 'Bemerkungen',
    update_partner_gateway_state_label: 'Stand aktualisieren',
    update_partner_gateway_state_button: 'Update'
  },

  role: {
    singular: 'Rechte-Gruppe',
    plural: 'Rechte-Gruppen',
    name: 'Bezeichnung',
    dsft: 'DSFT?'
  },
  public_report: {
    singular: 'Öffentlicher Prüfbericht',
    plural: 'Öffentliche Prüfberichte'
  },

  short_report: {
    singular: 'Kurzbericht',
    plural: 'Kurzberichte',
    label: {
      en: 'Kurzbericht in englischer Sprache',
      de: 'Kurzbericht in deutscher Sprache'
    },
    mental: 'Menschen mit kognitiven Beeinträchtigungen',
    wheelchair_and_walking: 'Menschen mit Gehbehinderung und Rollstuhlfahrer',
    visual_and_partially_visual: 'Menschen mit Sehbehinderung und blinde Menschen',
    deaf_and_partially_deaf: 'Menschen mit Hörbehinderung und gehörlose Menschen',
    allergic: 'Menschen mit Allergien',
    generations: 'Menschen aller Generationen',
    placeholder: 'Geben Sie hier einen Kurzbericht ein für:',
    generation: {
      button: 'automatischer Kurzbericht',
      heading: 'Kurzbericht automatisch generieren',
      regenerate: 'Kurzbericht neu generieren',
      loading: 'wird generiert...',
      error: 'Beim Generieren des Kurzberichtes ist ein Fehler aufgetreten.',
      report: 'Generierter Kurzbericht',
      info:
        'Dieser Kurzbericht wurde automatisch basierend auf den eingerichteten Textbausteinen und den Angaben in den Formularen des Betriebes erstellt.',
      hint:
        'Sie können ihn teilweise oder vollständig in den tatsächlichen Kurzbericht kopieren oder diesen vollständig durch die generierte Vorlage ersetzen.',
      copy: 'In die Zwischenablage kopieren',
      copied: 'Erfolgreich kopiert!',
      apply: 'Kurzbericht übernehmen'
    },
    variable: {
      singular: 'Baustein für Kurzberichte',
      plural: 'Bausteine für Kurzberichte',
      sections: {
        common: 'Allgemeine Informationen',
        facilityKinds: 'Für Betriebe mit mindestens einem dieser Angebotstypen ausgeben',
        certificateConfig: 'Für Betriebe mit mindestens einer dieser Zertifizierungen ausgeben',
        textTemplate: 'Inhalt'
      },
      name: 'Name',
      description: 'Beschreibung',
      show_for_guest_groups: 'Für folgende Kurzberichte ausgeben',
      selectFacilityKindIds: 'Angebotstypen auswählen',
      selectAllFacilityKindIds: 'Alle',
      text_template: 'Inhalt',
      certificateMissing: 'Keine Zertifizierung',
      actions: {
        create: 'Neuen Baustein anlegen',
        back_to_index: 'Zurück zur Übersicht',
        back_to_form: 'Zurück zum Formular',
        edit: name => `Textbaustein${name ? ` "${name}"` : ''} bearbeiten`,
        delete: name => `Textbaustein${name ? ` "${name}"` : ''} löschen`,
        confirm_delete: 'Wollen Sie den Textbaustein wirklich löschen?',
        updated_successfully: 'Die Änderungen wurden gespeichert.'
      }
    }
  },
  facility_kind: {
    name_de: 'Bezeichnung (de)',
    name_en: 'Bezeichnung (en)',
    add: 'Angebotstyp hinzufügen',
    singular: 'Angebotstyp',
    plural: 'Angebotstypen',
    created_at: 'erstellt am',
    label: 'Bezeichung',
    sections_count: 'Standardteilbereiche',
    parent_kind_id: 'Eltern-Angebotstyp',
    relevantForVolumeUnitCalculation: 'Relevant für Volumeneinheiten-Berechung'
  },
  facilityBundles: {
    bundledFacilities: {
      plural: 'Gebündelte Betriebe'
    }
  },
  region: {
    singular: 'Region',
    plural: 'Regionen',
    name_de: 'Region (de)',
    name_en: 'Region (en)'
  },
  widget_filter: {
    singular: 'Widget Filter',
    plural: 'Widget Filter',
    name_de: 'Widget Filter (de)',
    name_en: 'Widget Filter (en)'
  },
  provider: {
    singular: 'Anbieter',
    plural: 'Anbieter',
    add: 'Anbieter hinzufügen',
    delete: {
      title: name => `Anbieter '${name}' löschen`,
      confirm: (name, count) => (
        <Fragment>
          Wollen Sie den Anbieter <strong className="text-primary-light">{name}</strong> wirklich löschen?
          {count > 0 && ` Die zugehörigen ${count} Betriebe können dann nur noch individuell gefunden werden.`}
        </Fragment>
      ),
      loading: name => `'${name}' wird gelöscht...`
    },
    update: name => `${name} aktualisieren`,
    search: 'Anbieter durchsuchen...',
    name: 'Name',
    email: 'E-Mail',
    phone: 'Telefon',
    miscellaneous: 'Sonstiges',
    facilities: 'Zugeordnete Betriebe',
    address: {
      city: 'Stadt',
      street: 'Straße',
      street_addition: 'Straße (Zusatz)',
      zipcode: 'Postleitzahl',
      state: 'Bundesland',
      country_code: 'Land'
    }
  },
  public_requirements: {
    passed: 'erfüllt',
    not_relevant: 'nicht relevant',
    failed: 'nicht erfüllt',
    missing: 'nichts angegeben',
    calculating: 'wird berechnet...',
    category: 'Stufe',
    guest_group: {
      singular: 'Gästegruppe',
      wheelchair: 'Informationen für Menschen im Rollstuhl',
      walking: 'Informationen für Menschen mit Gehbehinderung',
      visual: 'Informationen für blinde Menschen',
      partially_visual: 'Informationen für Menschen mit Sehbehinderung',
      deaf: 'Informationen für gehörlose Menschen',
      partially_deaf: 'Informationen für Menschen mit Hörbehinderung',
      mental: ''
    }
  },
  partner_gateway_user: {
    plural: 'Partnergateway Nutzer',
    singular: 'Partnergateway Nutzer',
    name: 'Name',
    email: 'Email',
    delete: 'Nutzer vom Partnergateway entfernen',
    create: 'Nutzer hinzufügen',
    update: 'Nutzer Partnergateway bearbeiten',
    search_placeholder: 'Nutzer suchen...',
    organizations_help:
      'Nutzer, denen eine Organisation zugeordnet wird, erhalten Zugriff auf eingeschränkt sichtbare (öffentliche, aber nicht zertifizierte) Betriebe dieser Organisation. Nutzer ohne diese Zuweisung sehen diese Betriebe nicht.'
  },
  input_api_user: {
    plural: 'Input-API Nutzer',
    singular: 'Input-API Nutzer',
    name: 'Name',
    email: 'Email',
    delete: 'Input-API Nutzer entfernen',
    create: 'Nutzer hinzufügen',
    update: 'Input-API Nutzer bearbeiten',
    search_placeholder: 'Input-API Nutzer suchen...'
  },
  license_owner: {
    signature: 'Unterschrift',
    organization_help:
      'Betriebe, die beim Anlegen diesem Lizenznehmer zugewiesen werden, werden standardmäßig ebenfalls diesen Organisationen zugewiesen. Dies hat Auswirkungen auf z.B. das Layout der PDFs oder die Sichtbarkeit im PartnerGateway.',
    plural: 'Lizenznehmer',
    singular: 'Lizenznehmer',
    name: 'Name',
    signature_text: 'Unterschrift Text',
    address: {
      singular: 'Adresse',
      plural: 'Adressen',
      street: 'Straße',
      street_addition: 'Straße (Zusatz)',
      zipcode: 'Postleitzahl',
      city: 'Ort'
    },
    contact_person: {
      singular: 'Kontaktperson',
      plural: 'Kontaktpersonen',
      first_name: 'Vorname',
      last_name: 'Nachname',
      email: 'Email',
      title: 'Titel',
      phone: 'Telefon',
      function: 'Funktion',
      address: {
        singular: 'Adresse',
        plural: 'Adressen',
        street: 'Straße',
        street_addition: 'Straße (Zusatz)',
        zipcode: 'Postleitzahl',
        city: 'Ort'
      }
    }
  },

  section_datum: {
    name_de: 'Name (de)',
    name_en: 'Name (en)'
  },

  certification_data: {
    audit_order_number: 'Prüfauftragsnummer'
  },

  manuals: 'Handbuch & FAQ',

  online_training: {
    header: 'Online-Schulung',
    greeting: 'Sehr geehrte Damen und Herren',
    paragraphs: [
      'Betriebe oder Anbieter, die eine Zertifizierung nach „Reisen für Alle“ anstreben, müssen mindestens eine Mitarbeiterin / einen Mitarbeiter schulen lassen.',
      'Wir akzeptieren ein Präsenzseminar „Reisen für Alle“ oder die Online-Schulung „Reisen für Alle“.',
      'Falls ein Mitarbeiter / eine Mitarbeiterin bereits ein Seminar „Reisen für Alle“ (Präsenz- oder Online-Schulung) absolviert hat, sollte der Betrieb/Anbieter seinen Lizenznehmer informieren und diesem die Teilnahmebescheinigung des Seminars zusenden.',
      'Falls zusätzlich oder ausschließlich die Online-Schulung absolviert werden soll, können bis zu 10 Mitarbeiter/-innen kostenlos zur Online-Schulung „Reisen für Alle“ angemeldet werden.'
    ],
    registration: {
      header: 'Anmeldung zu Online-Schulung „Reisen für Alle“',
      text_before:
        'Durch Klicken auf den folgenden Link verlassen Sie diese Website und werden auf die Anmeldeseite für die Online-Schulung „Reisen für Alle“ weitergeleitet. Dort können Sie Ihren Betrieb und bis zu 10 Teilnehmer/-innen kostenlos anmelden. Beide Webseiten werden von der Bayern Tourist GmbH (BTG) betrieben.',
      button: {
        text: 'Jetzt registrieren',
        href: 'https://www.freiwerk-b.de/DSFT_Plattform/register.php?task=addcompany'
      },
      text_after: 'PS: Weitere Informationen zur Nutzung dieser Website finden Sie auch unter "Handbuch und FAQ".'
    },
    footer: {
      text: [
        'Ihr „Reisen für Alle“ Team',
        'Bayern Tourist GmbH (BTG)',
        'Prinz-Ludwig-Palais',
        'Türkenstraße 7',
        '80333 München',
        'Tel.: +49 (0)89-280 9899',
        'Fax: +49 (0)89-280 9931',
        <>
          E-Mail: <a href="mailto:bundeskoordinierung-rfa@btg-service.de">bundeskoordinierung-rfa@btg-service.de</a>
        </>
      ],
      links: ['www.btg-service.de', 'www.reisen-fuer-alle.de']
    }
  },
  form: {
    create_label: 'Formular hinzufügen',
    update_label: 'Formular bearbeiten',
    singular: 'Formular',
    plural: 'Formulare',
    name: 'Modulname',
    name_de: 'Name (de)',
    name_en: 'Name (en)',
    in_use: 'Aktiv',
    sheet_number: 'Modul-Nr.',
    order_no: 'Einfügen vor',
    form_version_id: 'Gehört zu Version'
  },
  field: {
    singular: 'Feld',
    plural: 'Felder',
    create_label: 'Feld hinzufügen',
    update_label: 'Feld bearbeiten',
    name: 'Frage',
    field_number: 'Fragenummer',
    parent_id: 'Gehört zu',
    order_no: 'Einfügen vor',
    kind: 'Typ',
    unit_de: 'Einheit (de)',
    unit_en: 'Einheit (en)',
    result_de: 'Antwort (de)',
    result_en: 'Antwort (en)',
    help_text: 'Hilfetext für Erheber',
    help_image: 'Hinweisbild für Erheber\n',
    heading: 'Als Überschrift formatiert',
    linked_form_ids: 'Verlinkte Formulare',
    embedded_form_id: 'Eingebettetes Formular',
    is_mandatory: 'Pflichtfeld',
    show_in_search: 'Anzeige in Suche',
    show_in_audit_report: 'im Prüfbericht enthalten',
    relevant_guest_groups: {
      label: 'Ausgabe für folgende Gästegruppen',
      allergic: 'Allergiker',
      deaf: 'Hörbehindert/ Gehörlos',
      generations: 'Bequem unterwegs für alle Generationen',
      mental: 'Kognitive Beeinträchtigungen',
      walking: 'Gehbehindert',
      wheelchair: 'Rollstuhlfahrer',
      visual: 'Sehbehindert/ Blind'
    },
    help_image_preview: 'aktuelles Hinweisbild'
  },
  requirement: {
    create_label: 'Anforderung hinzufügen',
    guest_group: 'Gästegruppe',
    category: 'Kategorie',
    label: 'Bedingung',
    alternative_requirement_ids: 'Alternativen'
  },
  kind: {
    Checkbox: 'Ja/Nein',
    CheckboxNA: 'Ja/Nein/Nicht anwendbar',
    String: 'Kurztext',
    Text: 'Freitext',
    Fixnum: 'Ganzzahl',
    Float: 'Gleitkommazahl',
    Area: 'Fläche',
    Select: 'Auswahlfeld',
    Multiselect: 'Mehrfachauswahlfeld',
    DecisionWithAddition: 'Entscheidung mit Zusatzfeldern'
  },
  event: {
    contract: {
      event: {
        file: 'Vereinbarung anfügen'
      }
    },
    file: 'Datei anfügen',
    comment: 'Kommentar',
    collection_desired_until: 'Spätester Erhebungstermin',
    collector_id: 'Erheber',
    collector_reward: 'Erheberhonorar',
    collection_scheduled_at: 'Termin zur Erhebung hinterlegen',
    collection_time: 'Uhrzeit',
    collection_date: 'Datum',
    listed: 'veröffentlichen',
    volumeUnitCost: 'Beim Lizenznehmer abzurechnende Volumeneinheiten',
    volumeUnitCostAddons: {
      pendingFormDuplication:
        'Aktuell werden im Hintergrund Formulardaten kopiert. Möglicherweise wird noch nicht die korrekte Formularanzahl oder Betriebsgröße angezeigt, sowie die endgültigen Volumeneinheiten vorausgefüllt. Zum aktualisieren bitte die Seite neu laden.',
      costCannotBeCalculated:
        'Auf Grund des zugewiesenen Angebotstyps oder der errechneten Betriebsgröße können keine Volumeneinheiten automatisch errechnet werden.',
      calculatedFacilitySize: 'Errechnete Betriebsgröße',
      formDateCount: 'Anzahl der Formulare',
      recertification: 'Rezertifizierung',
      firstCertification: 'Erstzertifizierung'
    }
  },
  form_datum: {
    name_de: 'Bezeichung (deutsch)',
    name_en: 'Bezeichung (english)',
    sheet_number: 'Fortlaufende Bogen-Nr.'
  },
  downloads: {
    hint: 'Dieser Betrieb wurde von folgenden Nutzern zur Offline Erhebung heruntergeladen',
    unkown_device: 'Unbekanntes Gerät',
    device: 'Gerät',
    time: 'Heruntergeladen'
  },
  third_party_ids: {
    plural: 'Drittanbieter IDs',
    singular: 'Drittanbieter ID',
    key: 'Typ',
    value: 'Wert',
    create: 'Drittanbieter ID hinzufügen',
    empty: 'Es sind keine Drittanbieter IDs vorhanden.'
  },
  serviceworker: {
    updatePrompt: {
      title: 'Neue Version verfügbar',
      info:
        'Es ist eine neue Anwendungsversion verfügbar. Bitte updaten Sie jetzt, um die korrekte Funktion der Anwendung sicherzustellen.',
      action: 'Jetzt aktualisieren'
    }
  },
  asset_download: {
    singular: 'Piktogramm Download-Link',
    plural: 'Piktogramm Download-Links',
    url: 'Link zur Downloadseite',
    urlLink: 'Zur Downloadseite',
    updateUrl: 'Link aktualisieren',
    downloadTitle: 'Download Logos und Piktogramme',
    downloadMissing:
      'Achtung, zu Ihrer Zertifizierung gibt es keine Downloadseite. Bitte wenden Sie sich ggf. an Ihren Lizenznehmer.',
    combinationKey: 'Kombinationskennung',
    combinationKeys: {
      wheelchair: 'R',
      walking: 'G',
      visual: 'B',
      partially_visual: 'S',
      deaf: 'L',
      partially_deaf: 'H',
      mental: 'K',
      info: 'I'
    },
    saveUrl: 'speichern'
  },
  volumeUnits: {
    singular: 'Volumeneinheit',
    plural: 'Volumeneinheiten'
  },
  pagination: {
    results: ({ total }) => `${total} ${total == 1 ? 'Ergebnis' : 'Ergebnisse'}`
  },
  userInterface: {
    monthRangePicker: {
      formattedValue: {
        singleMonth: month => `im ${month}`,
        betweenMonths: (fromMonth, toMonth) => `zwischen ${fromMonth} und ${toMonth}`,
        incomplete: month => `zwischen ${month} und …`
      }
    },
    select: {
      asyncUserSelect: {
        placeholder: 'Nutzer auswählen',
        nothingFound: 'Keine passenden Nutzer gefunden'
      }
    }
  }
}
