import { useId } from 'react'
import { useState } from 'react'

import { Col, FormFeedback, FormGroup, Label } from 'reactstrap'

import { AsyncSelect } from 'components/ui/select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getPossibleSalutationsForNewAssignedFacilityContact } from '../facilityContactForm.graphql'

const dataToOptionsMapper = data => data.possibleSalutations.map(({ key, label }) => ({ value: key, label }))

const SalutationSelect = ({ onChange: onChangeCallback, error, disabled = false, contactUserLocale }) => {
  const salutationSelectId = useId()
  const [salutation, setSalutation] = useState(null)

  const onChange = ({ value }) => {
    setSalutation(value)
    onChangeCallback(value)
  }

  return (
    // NOTE: removing top margin, because it is the first element in the form
    <FormGroup row className="mt-0 mb-3">
      <Label for={salutationSelectId} sm={2}>
        {contactUserLocale.salutation}
      </Label>
      <Col sm={10}>
        <AsyncSelect
          id={salutationSelectId}
          query={getPossibleSalutationsForNewAssignedFacilityContact}
          dataToOptionsMapper={dataToOptionsMapper}
          onChange={onChange}
          isDisabled={disabled}
          invalid={!!error}
          selectedOptionValues={[salutation]}
        />
        <FormFeedback valid={!error}>{error}</FormFeedback>
      </Col>
    </FormGroup>
  )
}

SalutationSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  contactUserLocale: PropTypes.shape({
    salutation: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(SalutationSelect, { attributeName: 'contactUserLocale', key: 'contact_user' })
