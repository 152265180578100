import { useContext, useState } from 'react'

import { Alert, Col, Form, Row } from 'reactstrap'

import { useMutation } from '@apollo/client'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'

import { ProcessingButton } from 'components/modules/buttons/processing-button'
import { GraphQlError } from 'components/ui/genericComponents/errorHandling'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getAssignedUsersForFacility } from '../../../../assignedUsersList.graphql'
import { createNewUserAndAssignAsFacilityContact } from './facilityContactForm.graphql'

import { DispatchContext, actionIds } from '../../../createAssignmentModalContext'
import HeadingWithExtraInfo from './createNewUser/headingWithExtraInfo'
import SalutationSelect from './createNewUser/salutationSelect'
import StringInput from './createNewUser/stringInput'

const CreateNewUser = ({ facilityUuid, locale, contactUserLocale }) => {
  const modalDispatch = useContext(DispatchContext)

  const closeModal = () => modalDispatch({ type: actionIds.CLOSE_MODAL })
  const lockModal = () => modalDispatch({ type: actionIds.LOCK_MODAL })
  const unlockModal = () => modalDispatch({ type: actionIds.UNLOCK_MODAL })

  const [userData, setUserData] = useState({})

  const changeSalutation = salutation => setUserData({ ...userData, salutation })
  const changeFirstName = firstName => setUserData({ ...userData, first_name: firstName })
  const changeLastName = lastName => setUserData({ ...userData, last_name: lastName })
  const changeEmail = email => setUserData({ ...userData, email })
  const changePhone = phone => setUserData({ ...userData, phone })

  const [createAndAssignUserMutation, { loading, error }] = useMutation(createNewUserAndAssignAsFacilityContact, {
    onError: unlockModal,
    refetchQueries: [getAssignedUsersForFacility],
    awaitRefetchQueries: true,
    onCompleted: _ => {
      unlockModal()
      closeModal()
    }
  })

  const onSubmit = event => {
    event.preventDefault()

    lockModal()

    createAndAssignUserMutation({
      variables: { facilityUuid, user: userData }
    })
  }

  const errorMessage =
    error?.message === 'validationFailed' ? (
      // NOTE: we don't really have a standard format for validation errors, so we also don't have a standard component to handle them
      Object.keys(error.graphQLErrors[0].validationErrors).map(field => (
        <Alert color="danger" key={field}>
          {locale.validationErrors[field][error.graphQLErrors[0].validationErrors[field]]}
        </Alert>
      ))
    ) : (
      <GraphQlError error={error} />
    )

  const validationErrors = error?.graphQLErrors[0]?.errors?.contact_user || {} // NOTE: assigning empty object by default, so attribute lookup below just works

  return (
    <>
      <HeadingWithExtraInfo />

      <Form onSubmit={onSubmit}>
        {errorMessage}
        <fieldset disabled={loading}>
          <SalutationSelect onChange={changeSalutation} disabled={loading} error={validationErrors.salutation} />
          <StringInput
            label={contactUserLocale.first_name}
            onChange={changeFirstName}
            error={validationErrors.first_name}
          />
          <StringInput
            label={contactUserLocale.last_name}
            onChange={changeLastName}
            error={validationErrors.last_name}
          />
          <StringInput label={contactUserLocale.email} onChange={changeEmail} error={validationErrors.email} />
          <StringInput label={contactUserLocale.phone} onChange={changePhone} error={validationErrors.phone} />

          <Row className="my-3">
            <Col xs={12}>
              <ProcessingButton
                type="submit"
                color="primary"
                className="float-right"
                label={locale.submit}
                processing_label={locale.submit}
                icon={faUserPlus}
                processing={loading}
              />
            </Col>
          </Row>
        </fieldset>
      </Form>
    </>
  )
}

CreateNewUser.propTypes = {
  facilityUuid: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    submit: PropTypes.string.isRequired,
    validationErrors: PropTypes.shape({
      role: PropTypes.shape({
        cannotAssignMoreOfThisType: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  contactUserLocale: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(
  withLocale(CreateNewUser, {
    key: 'facility.userAssignments.createAssignment.forms.FACILITY_CONTACT.createAndAssignNewUser'
  }),
  { attributeName: 'contactUserLocale', key: 'contact_user' }
)
