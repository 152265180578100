import React from 'react'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { getPropertyFromObject } from '../../helper/helper-functions'

const SelectCountry = ({ possible_countries, id, variables, onChange, errors }) => (
  <React.Fragment>
    <Select
      id={id}
      style={
        getPropertyFromObject(errors, id) && {
          borderColor: '#e53012'
        }
      }
      placeholder={'auswählen'}
      isClearable={false}
      name={id}
      value={getPropertyFromObject(variables, id)}
      onChange={onChange}
      options={possible_countries.map(country => {
        return {
          value: country.key,
          label: country.label,
          target: { id, value: country.key }
        }
      })}
    />
    {!!getPropertyFromObject(errors, id) && (
      <div
        style={{
          width: '100%',
          marginTop: '0.25rem',
          fontSize: '80%',
          color: '#e53012'
        }}
      >
        {getPropertyFromObject(errors, id)}
      </div>
    )}
  </React.Fragment>
)
export default SelectCountry
