import * as Sentry from '@sentry/browser'

import { fakeHeader } from '.'
import { graphql_uri } from '../../config/graphql'

/**
 *  Respond with cache if the resource extists, otherwise use the network to retrieve it
 *  offline fallback (not in cache and no network)
 */
const cacheOrNetwork = (event, options) => {
  console.log('cacheOrNetwork', event)
  if (event.request.url === graphql_uri) {
    return fetch(event.request)
  }

  return caches
    .match(event.request)
    .then(response => response || fetch(event.request))
    .catch(error => {
      console.error('CACHE OR NETWORK', error)
      console.error(error)
      Sentry.captureException(error)
      if (event.request.headers.get('Content-Type') == 'application/json') {
        return new Response(JSON.stringify({}), fakeHeader(event))
      }
      return caches.match('/index.html')
    })
}

export default cacheOrNetwork
