// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/graphiql/graphiql.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".graphiql-container {\n  height: calc(100vh - 80px);\n  width: 100vw;\n  --font-size-hint: 12px;\n  --font-size-inline-code: 13px;\n  --font-size-body: 15px;\n  --font-size-h4: 18px;\n  --font-size-h3: 22px;\n  --font-size-h2: 29px;\n}", "",{"version":3,"sources":["webpack://./src/components/views/debug/graphiql.scss"],"names":[],"mappings":"AAEA;EACE,0BAAA;EACA,YAAA;EAKA,sBAAA;EACA,6BAAA;EACA,sBAAA;EACA,oBAAA;EACA,oBAAA;EACA,oBAAA;AAJF","sourcesContent":["@import 'graphiql/graphiql.css';\n\n.graphiql-container {\n  height: calc(100vh - 80px);\n  width: 100vw;\n\n  // HACK we have to override these, because out application sets the default\n  // size to 62.5% instead of 100%, which makes everything smaller\n  // source: https://github.com/graphql/graphiql/blob/e01b6e076d77bdd83a6bac857bed2ad439dd3936/packages/graphiql-react/src/style/root.css#L35\n  --font-size-hint: 12px;\n  --font-size-inline-code: 13px;\n  --font-size-body: 15px;\n  --font-size-h4: 18px;\n  --font-size-h3: 22px;\n  --font-size-h2: 29px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
